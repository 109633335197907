body {
  margin: 0;
  font-family: "Barlow", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Barlow" !important;
}

:root {
  --toastify-color-light: #fff !important;
  --toastify-color-dark: #24405c !important;
  --toastify-color-info: #3498db !important;
  --toastify-color-success: #e7fbbe !important;
  --toastify-color-warning: #f28b24 !important;
  --toastify-color-error: #fa5081 !important;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7) !important;

  --toastify-icon-color-info: var(--toastify-color-info) !important;
  --toastify-icon-color-success: var(--toastify-color-success) !important;
  --toastify-icon-color-warning: var(--toastify-color-warning) !important;
  --toastify-icon-color-error: var(--toastify-color-error) !important;

  --toastify-toast-width: 320px !important;
  --toastify-toast-background: #fff !important;
  --toastify-toast-min-height: 64px !important;
  --toastify-toast-max-height: 800px !important;
  --toastify-font-family: sans-serif !important;
  --toastify-z-index: 9999 !important;

  --toastify-text-color-light: #757575 !important;
  --toastify-text-color-dark: #fff !important;

  --toastify-text-color-info: #fff !important;
  --toastify-text-color-success: #fff !important;
  --toastify-text-color-warning: #fff !important;
  --toastify-text-color-error: #fff !important;

  --toastify-spinner-color: #616161 !important;
  --toastify-spinner-color-empty-area: #e0e0e0 !important;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  ) !important;

  --toastify-color-progress-dark: #bb86fc !important;
  --toastify-color-progress-info: var(--toastify-color-info) !important;
  --toastify-color-progress-success: var(--toastify-color-success) !important;
  --toastify-color-progress-warning: var(--toastify-color-warning) !important;
  --toastify-color-progress-error: var(--toastify-color-error) !important;
}
